<template>
  <div class="people-container">
    <!-- Filter Section -->
    <div class="accordion" id="peopleFilterAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingPeopleFilter">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#peopleFilters"
            aria-expanded="true" aria-controls="peopleFilters">
            Filters (Click to Open)
          </button>
        </h2>
        <div id="peopleFilters" class="accordion-collapse collapse show" aria-labelledby="headingPeopleFilter">
          <div class="accordion-body">
            <div class="row">
              <!-- Name Filter -->
              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label for="nameFilter" class="form-label">Name</label>
                <input type="text" id="nameFilter" v-model="nameFilter" class="form-control"
                  placeholder="Search by Name" />
              </div>
              <!-- Description Filter -->
              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label for="descriptionFilter" class="form-label">Description</label>
                <input type="text" id="descriptionFilter" v-model="descriptionFilter" class="form-control"
                  placeholder="Search by Description" />
              </div>
              <!-- Start Date Filter -->
              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label for="startDateFilter" class="form-label">Start Date</label>
                <input type="date" id="startDateFilter" v-model="startDateFilter" class="form-control" />
              </div>
              <!-- End Date Filter -->
              <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <label for="endDateFilter" class="form-label">End Date</label>
                <input type="date" id="endDateFilter" v-model="endDateFilter" class="form-control" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <button class="btn btn-primary w-100" @click="fetchPeople(1)">Search</button>
              </div>
              <div class="col-6">
                <button class="btn btn-secondary w-100" @click="clearFilters">Clear Filters</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination Component -->
    <Pagination v-if="pagination.total > 0" :config="pagination" @search="onPageChange" />

    <!-- People Display Section -->
    <div class="people-list">
      <h2>People</h2>
      <div class="row">
        <div class="col-md-4 mb-3" v-for="person in people" :key="person.id">
          <div class="card">
            <img :src="getPersonImage(person)" alt="Person Picture" class="card-img-top">
            <div class="card-body">
              <h5 class="card-title">{{ person.name }}</h5>
              <p class="card-text">{{ person.description }}</p>
              <p v-if="person.faces && person.faces.length > 0">
                Age Range: {{ person.faces[0].age_min }} - {{ person.faces[0].age_max }}
              </p>
              <small class="text-muted">Created at: {{ formatDateTimeISO(person.created_at) }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <!-- Pagination Component -->
    <Pagination v-if="pagination.total > 0" :config="pagination" @search="onPageChange" />
  </div>
</template>

<script>
import axios from "axios";
import Pagination from '../../components/Pagination.vue';
import ClientService from '../../services/client.service.js';
import { v4 as uuidv4 } from 'uuid';
import authHeader from "../../services/auth-header";

export default {
  name: "People",
  components: {
    Pagination,
  },
  data() {
    return {
      people: [],
      pagination: {
        page: 1,        // Current page
        total: 0,       // Total number of items
        pageSize: 25,   // Number of items per page
        maxPage: 0      // Total number of pages
      },
      nameFilter: "",
      descriptionFilter: "",
      startDateFilter: "",
      endDateFilter: "",
      siteIdFilter: null,
      claim: {
        site: null,
        postcode: null,
        comments: null,
        fuels: [
          {
            id: Math.floor((Math.random() * 10) + 1),
            type: "",
            value: null,
            litres: null,
            pump: null
          }
        ],
        evidence: [
          {
            id: uuidv4(),
            file: null,
            type: "vehicle_image",
            comments: null,
            error: false,
            errorMsg: "",
            permanent: true,
            src: null,
            isPdf: false
          },
          {
            id: uuidv4(),
            file: null,
            type: "till_receipt",
            comments: null,
            error: false,
            errorMsg: "",
            permanent: true,
            src: null,
            isPdf: false
          }
        ],
        accept_agreement: false,
        date: null,
        time: null,
      },
    };
  },

  async created() {
    await this.loadSites();
    this.fetchPeople();
  },

  methods: {
    async fetchPeople(page = 1) {
      this.pagination.page = page;
      this.isLoading = true;
      this.error = null;
      const params = {
        site_id: this.siteIdFilter, // Compulsory
        name: this.nameFilter,
        description: this.descriptionFilter,
        start: this.startDateFilter,   // Assuming you have a startFilter data property for start date
        end: this.endDateFilter,
        page: this.pagination.page,
        count: this.pagination.pageSize
      };

      // Construct start and end date-time strings
      if (this.startDate && this.endDate) {
        const formattedStartDate = new Date(this.startDate);
        const formattedEndDate = new Date(this.endDate);

        const startYear = formattedStartDate.getFullYear();
        const startMonth = String(formattedStartDate.getMonth() + 1).padStart(2, '0');
        const startDay = String(formattedStartDate.getDate()).padStart(2, '0');
        const endYear = formattedEndDate.getFullYear();
        const endMonth = String(formattedEndDate.getMonth() + 1).padStart(2, '0');
        const endDay = String(formattedEndDate.getDate()).padStart(2, '0');

        params.start = `${startYear}-${startMonth}-${startDay}`;
        params.end = `${endYear}-${endMonth}-${endDay}`;

        console.log(params.start);
        console.log(params.end);
      }

      // Adjust name filter for LIKE pattern
      if (this.nameFilter) {
        params.name = `%${this.nameFilter}%`;
      }

      // Prepare custom headers
      const headers = {
        'x-vars-claims': '{"id":4,"permissions":["people.read", "incident.read", "incident.create", "faces.read", "sites.read", "events-blacklist", "cameras-edit", "cameras-view", "sites-map", "logs-view", "staging-manage","users-edit", "claims-process","claims-view", "clients-edit", "accounting-view", "roles-view", "roles-edit", "permissions-view", "sites-view", "reports-view", "reports-claims-view", "nmop-view", "driveoff-view", "cnaf-view", "parking-view", "scheduler-view", "scheduler-delete", "tablets-view", "scheduler-create", "calls-view", "claims-manage-team"]}',
      };

      try {
        const response = await axios.get("http://localhost:7071/people", {
          params,
          headers
        });
        console.log(response.data);
        this.people = response.data.people;
        console.log(this.people);
        this.people.forEach(person => {
          if (person.faces && person.faces.length > 0) {
            person.pictureUrl = person.faces[0].background_sas;
          }
        });
        // Update pagination data based on API response
        this.pagination.total = response.data.pagination.total;
        this.pagination.maxPage = Math.ceil(this.pagination.total / this.pagination.pageSize);
      } catch (error) {
        console.error('Error fetching people:', error);
        // Handle errors appropriately
      }
    },

    getPersonImage(person) {
      // Return the first face's background_sas URL or a default image
      const imageUrl = person.pictureUrl || 'path_to_default_image.jpg';
      console.log(`Image URL for person ${person.id}: ${imageUrl}`);
      return imageUrl;
    },

    formatDateTimeISO(isoString) {
      // Parses the ISO string into a Date object and formats it
      const date = new Date(isoString);
      return date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }) +
        ' ' +
        date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    },

    onPageChange(newPage) {
      this.fetchPeople(newPage);
    },

    clearFilters() {
      // Reset filter values to their initial state
      this.startDateFilter = "";
      this.endDateFilter = "";
      this.nameFilter = "";
      this.descriptionFilter = "";

      // Fetch data with cleared filters
      this.fetchPeople(1);
    },

    async loadSites() {
      try {
        const response = await ClientService.getClientSites(this.$store.state.auth.user.selectedClient);
        this.sites = response.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        this.claim.site = this.sites[3];
        this.siteIdFilter = this.claim.site.id;
        console.log(`Loaded site with ID: ${this.siteIdFilter}`);
      } catch (error) {
        console.error('Error loading sites:', error);
      }
    },
  },
};
</script>

<style scoped>
.people-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.person-item {
  margin: 10px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.person-details {
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
}
</style>
